//验证是否为IP
export default {
  isValidIP: function(ip) {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    return reg.test(ip);
  },
  //验证不超过后端int类型
  validInt: (val) => {
    if (val) {
      if (
        /^[0-9]{0,10}$/.test(val) &&
        Number(val) >= 0 &&
        Number(val) <= 2147483647
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  },

  /**
   * 格式化时间
   */
  getFormatTime: function(msec) {
    let ss = parseInt(msec / 1000);
    let ms = parseInt(msec % 1000);
    let mm = 0;
    let hh = 0;
    if (ss > 60) {
      mm = parseInt(ss / 60);
      ss = parseInt(ss % 60);
      if (mm > 60) {
        hh = parseInt(mm / 60);
        mm = parseInt(mm % 60);
      }
    }
    ss = ss > 9 ? ss : `0${ss}`;
    mm = mm > 9 ? mm : `0${mm}`;
    hh = hh > 9 ? hh : `0${hh}`;
    return { ms, ss, mm, hh };
  },

  // 校验名字长度不能超过20
  validName: (val) => {
    if (val) {
      if (val.length > 20) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  },
  getParam: function(paramName) {
    // 获取参数
    var url = window.location.search;
    // 正则筛选地址栏
    var reg = new RegExp("(^|&)" + paramName + "=([^&]*)(&|$)");
    // 匹配目标参数
    var result = url.substr(1).match(reg);
    //返回参数值
    return result ? decodeURIComponent(result[2]) : null;
  },
  clearNoNum: function(obj) {
    obj.value = obj.value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
    obj.value = obj.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
    obj.value = obj.value
      .replace(".", "$#$")
      .replace(/\./g, "")
      .replace("$#$", ".");
    obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
    if (obj.value.indexOf(".") < 0 && obj.value != "") {
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      obj.value = parseFloat(obj.value);
    }
  },
  // 加
  accAdd: function(arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (Math.round(arg1 * m) + Math.round(arg2 * m)) / m;
  },
  // 除
  accDiv: function(arg1, arg2) {
    var t1 = 0,
      t2 = 0,
      r1,
      r2;
    var t1 = 0,
      t2 = 0,
      r1,
      r2;
    try {
      t1 = arg1.toString().split(".")[1].length;
    } catch (e) {}
    try {
      t2 = arg2.toString().split(".")[1].length;
    } catch (e) {}
    try {
      r1 = Number(arg1.toString().replace(".", ""));
      r2 = Number(arg2.toString().replace(".", ""));
    } catch (e) {}
    return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  // 减
  Subtr: function(arg1, arg2) {
    var r1, r2, m, n;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
  },
  // 乘
  accMul: function(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();

    try {
      m += s1.split(".")[1].length;
    } catch (e) {}

    try {
      m += s2.split(".")[1].length;
    } catch (e) {}

    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  },
  // 数组移动
  swapItems: function(arr, index1, index2) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0];
    return arr;
  },
  stringCutOut: function(str, num) {
    if (str.length > num) return str.substring(0, num) + "...";
    return str;
  },
  getIntervalHour: function(s1, s2) {
    s1 = new Date(s1.replace(/-/g, "/"));
    s2 = new Date(s2.replace(/-/g, "/"));
    var ms = Math.abs(s1.getTime() - s2.getTime());
    return parseInt(ms / 1000 / 60 / 60);
  },
  toThousands: function(num) {
    num = num.toString().replace(/$|\,/g, "");
    if (isNaN(num)) num = "0";
    let sign = num == (num = Math.abs(num));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        "," +
        num.substring(num.length - (4 * i + 3));
    return (sign ? "" : "-") + num + "." + cents;
  },
  format_number: function(srcNumber, n) {
    //n是要保留的位数
    var dstNumber = parseFloat(srcNumber);
    if (isNaN(dstNumber)) {
      return srcNumber;
    }
    if (dstNumber >= 0) {
      dstNumber = parseInt(dstNumber * Math.pow(10, n) + 0.5) / Math.pow(10, n); //关键点
    } else {
      var tmpDstNumber = -dstNumber;
      dstNumber =
        -parseInt(tmpDstNumber * Math.pow(10, n) + 0.5) / Math.pow(10, n);
    }
    var dstStrNumber = dstNumber.toString();
    var dotIndex = dstStrNumber.indexOf(".");
    if (dotIndex < 0) {
      dotIndex = dstStrNumber.length;
      dstStrNumber += ".";
    }

    while (dstStrNumber.length <= dotIndex + n) {
      dstStrNumber += "0";
    }
    return dstStrNumber;
  },

  formatMoney: function(s, n) {
    if (s === "" || s == "underfined" || s == undefined || s == null) {
      return;
    }
    n = n > 0 && n <= 20 ? n : 4;
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    var l = s
        .split(".")[0]
        .split("")
        .reverse(),
      r = s.split(".")[1];
    var t = "";
    for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
    }
    return (
      t
        .split("")
        .reverse()
        .join("") +
      "." +
      r
    );
  },

  // 格式化手机号
  formatMobile: function(tel) {
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(tel.replace(/\s+/g, ""))) {
      return tel;
    }
    return `${tel.substr(0, 3)}****${tel.substr(7, 11)}`;
  },

  // 验证手机号
  regMobile: function(tel) {
    return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(tel);
  },
};
