<template>
  <div id="app" v-if="!stop">
    <router-view v-show="!loading" />
    <div v-show="loading" class="loading-page">
      <van-loading type="spinner" />
    </div>
    <div
      v-show="showBack"
      :class="['back-home', backClass]"
      ref="tuozhuai"
      @click="clickBack1"
      @touchstart="down"
      @touchmove="move"
      @touchend="end"
    >
      <img src="./assets/images/back_home.png" alt="" /> 首页
    </div>
    <we-chat-modal />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Loading } from "vant";
import mathManage from "@/utils/mathManage";
import wx from "weixin-js-sdk";
import { checkTenantStatus } from "@/api/common";
import WeChatModal from "./components/WeChatModal.vue";
export default {
  name: "App",
  components: {
    [Loading.name]: Loading,
    WeChatModal,
  },
  data() {
    return {
      stop: true, // 默认先不加载页面
      moreBack: false,
      flags: false,
      position: { x: 0, y: 0 },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      nowEle: null,
    };
  },
  computed: {
    ...mapState(["loading", "noPage", "isLogin", "isInWx"]), //isLogin 判断是否登录, isInWx 判断是否是微信环境
    backClass() {
      return this.moreBack ? "active" : "";
    },
    showBack() {
      return this.$route.meta.showBack;
    },
  },
  watch: {
    $route(to, from) {
      // 监听路由  路由变化的时候  关闭客服弹框
      this.setServiceModal(false);
    },
  },
  async created() {

    // document
    //   .querySelector('meta[name="description"]')
    //   .setAttribute("content", "员数字商品批发采购,兑换码,欢迎选购");

    this.getIsMiniProgram();
    try {
      const res = await checkTenantStatus();
      this.stop = false;
      if (res && res.data) {
        this.setWebSiteDisabled(false);
      } else if (res && !res.data) {
        this.$router.push("/404");
      }
    } catch (error) {
      this.stop = false;
    }
  },
  mounted() {
    this.getMallInfo();
    document.addEventListener("scroll", this.handleScroll, true);
    document.addEventListener("click", this.handleClick, true);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll, true);
    document.removeEventListener("click", this.handleClick, true);
  },

  methods: {
    ...mapMutations([
      "setLoginStatus",
      "setWebSiteDisabled",
      "setIsMiniProgram",
      "setServiceModal",
    ]),
    ...mapActions(["getFpUserStatus", "getMallInfo"]),
    clickBack() {
      if (this.moreBack) {
        this.moreBack = false;
        setTimeout(() => {
          this.$router.push("/mall/home");
        }, 500);
      } else {
        this.moreBack = true;
      }
    },
    clickBack1() {
      this.$router.push("/mall/home");
    },
    handleScroll() {
      let scrollTop = document.getElementById("app").scrollTop;
      if (scrollTop > 100) {
        this.moreBack = true;
      } else {
        this.moreBack = false;
      }
    },
    handleClick(e) {
      this.moreBack = false;
      if (e.target.className == "back-home active") {
        this.$router.push("/mall/home");
      }
    },

    getIsMiniProgram() {
      wx.miniProgram.getEnv((res) => {
        this.setIsMiniProgram(res.miniprogram);
      });
    },

    down() {
      let default_drag_comp = this.$refs.tuozhuai;
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.maxW = document.body.clientWidth - default_drag_comp.offsetWidth;
      this.maxH = document.body.clientHeight - default_drag_comp.offsetHeight;
      this.position.x = touch.clientX - default_drag_comp.offsetLeft;
      this.position.y = touch.clientY - default_drag_comp.offsetTop;
      this.dx = touch.clientX;
      this.dy = touch.clientY;
    },
    move(event) {
      event.preventDefault();
      let default_drag_comp = this.$refs.tuozhuai;
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;

        if (this.nx < 0) {
          this.nx = 0;
        } else if (this.nx > this.maxW) {
          this.nx = this.maxW;
        }

        if (this.ny < 0) {
          this.ny = 0;
        } else if (this.ny >= this.maxH) {
          this.ny = this.maxH;
        }
        default_drag_comp.style.left = this.nx + "px";
        console.log();
        default_drag_comp.style.top = this.ny + "px";
        //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
        document.addEventListener(
          "touchmove",
          function () {
            // event.preventDefault();
          },
          false
        );
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
      let default_drag_comp = this.$refs.tuozhuai;
      default_drag_comp.style.left = "86%";
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f6f7f7;

  .loading-page {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lose-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vh;
    height: 100vh;
    overflow: hidden;
    background-color: #fff;

    .text {
      font-size: 0.3rem;
      color: #ccc;
    }
  }

  .back-home {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 1.2rem;
    width: 1.1rem;
    height: 0.6rem;
    background: rgba(254, 35, 1, 0.8);
    color: #fff;
    border-radius: 0.34rem 0 0 0.34rem;
    font-size: 0.26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0 linear;
    padding-left: 0.08rem;

    img {
      width: 0.22rem;
      height: 0.24rem;
      margin-right: 0.08rem;
    }
  }
}
</style>
