import Vue from "vue";
import VueRouter from "vue-router";

const Mall = (resolve) => require(["../views/Mall.vue"], resolve);
const Home = (resolve) => require(["../views/Home.vue"], resolve);
const Goods = (resolve) => require(["../views/Goods.vue"], resolve);
const Ticket = (resolve) => require(["../views/Ticket.vue"], resolve);
const Account = (resolve) => require(["../views/Account.vue"], resolve);
const OrderList = (resolve) => require(["../views/OrderList.vue"], resolve);
const OrderConfirm = (resolve) =>
  require(["../views/OrderConfirm.vue"], resolve);
const CashierDesk = (resolve) => require(["../views/CashierDesk.vue"], resolve);
const PayDetails = (resolve) => require(["../views/PayDetails.vue"], resolve);
const OrderDetails = (resolve) =>
  require(["../views/OrderDetails.vue"], resolve);
const Integral = (resolve) =>
  require(["../views/IntegralDetails.vue"], resolve);
const SearchGood = (resolve) => require(["../views/SearchGood.vue"], resolve);
const ProductDetails = (resolve) =>
  require(["../views/ProductDetails.vue"], resolve);
const Setting = (resolve) => require(["../views/Setting.vue"], resolve);
const JsapiPage = (resolve) =>
  require(["../views/jsapiPayDetails.vue"], resolve);
const GetTicket = (resolve) => require(["../views/getTicket.vue"], resolve);
const ServiceDetails = (resolve) =>
  require(["../views/ServiceDetails.vue"], resolve);
const ServiceList = (resolve) => require(["../views/ServiceList.vue"], resolve);
const PhoneBill = (resolve) => require(["../views/PhoneBill.vue"], resolve);
const CashierHandlePage = (resolve) =>
  require(["../views/CashierHandlePage.vue"], resolve);
const PayHandlePage = (resolve) =>
  require(["../views/PayHandlePage.vue"], resolve);
const Common = (resolve) => require(["../views/Common.vue"], resolve);
const NotFound = (resolve) => require(["../views/NotFound.vue"], resolve);
const CouponCenter = (resolve) =>
  require(["../views/CouponCenter.vue"], resolve);
const CouponSearch = (resolve) =>
  require(["../views/CouponSearchList.vue"], resolve);
const SearchCoupon = (resolve) =>
  require(["../views/SearchCoupon.vue"], resolve);

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

// 使用 挂载
Vue.use(VueRouter);

// 定义路由规则
const routes = [
  {
    path: "/common",
    name: "common",
    component: Common,
  },
  {
    path: "/ticket",
    name: "ticket",
    component: GetTicket,
    meta: {
      isTicket: true, // 是否是领券页面
    },
  },
  {
    path: "/404",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/mall/payHandlePage",
    name: "payHandlePage",
    component: PayHandlePage,
    meta: {
      showTabbar: false,
      notWxLogin: true,
    },
  },
  {
    path: "/", // 路由路径
    redirect: "/mall",
  },
  {
    path: "/mall",
    name: "mall",
    component: Mall,
    redirect: "/mall/home",
    children: [
      {
        path: "/mall/home",
        name: "home",
        component: Home,
        meta: {
          showTabbar: true,
        },
      },
      {
        path: "/mall/goods",
        name: "goods",
        component: Goods,
        meta: {
          showTabbar: true,
        },
      },
      {
        path: "/mall/ticket",
        name: "ticketList",
        component: Ticket,
        meta: {
          showTabbar: true,
        },
      },
      {
        path: "/mall/account",
        name: "account",
        component: Account,
        meta: {
          showTabbar: true,
        },
      },
      {
        path: "/mall/orderList",
        name: "orderList",
        component: OrderList,
        meta: {
          showTabbar: true,
        },
      },
      {
        path: "/mall/orderConfirm/:id",
        name: "orderConfirm",
        component: OrderConfirm,
        meta: {
          showTabbar: false,
          toHome: true,
        },
      },
      {
        path: "/mall/cashierHandlePage",
        name: "cashierHandlePage",
        component: CashierHandlePage,
        meta: {
          showTabbar: false,
          notWxLogin: true,
        },
      },
      {
        path: "/mall/cashierDesk",
        name: "cashierDesk",
        component: CashierDesk,
        meta: {
          showTabbar: false,
          toHome: true,
        },
      },
      {
        path: "/mall/Integral",
        name: "Integral",
        component: Integral,
        meta: {
          showTabbar: false,
          toHome: true,
        },
      },
      {
        path: "/mall/payDetails",
        name: "payDetails",
        component: PayDetails,
        meta: {
          showTabbar: false,
          toHome: true,
        },
      },
      {
        path: "/mall/orderDetails/:id",
        name: "orderDetails",
        component: OrderDetails,
        meta: {
          showTabbar: false,
          toHome: true,
        },
      },
      {
        path: "/mall/SearchGood",
        name: "SearchGood",
        component: SearchGood,
        meta: {
          showTabbar: false,
        },
      },
      {
        path: "/mall/ProductDetails",
        name: "ProductDetails",
        component: ProductDetails,
        meta: {
          showTabbar: false,
          showBack: true,
        },
      },
      {
        path: "/mall/setting",
        name: "setting",
        component: Setting,
        meta: {
          showTabbar: false,
        },
      },
      {
        path: "/mall/jsapiPay",
        name: "jsapiPay",
        component: JsapiPage,
        meta: {
          showTabbar: false,
          notWxLogin: true, // 禁止微信登录
        },
      },
      {
        path: "/mall/serviceList",
        name: "serviceList",
        component: ServiceList,
        meta: {
          showTabbar: true,
          toHome: true,
        },
      },
      {
        path: "/mall/serviceDetails/:id",
        name: "serviceDetails",
        component: ServiceDetails,
        meta: {
          showTabbar: false,
          toHome: true,
        },
      },
      {
        path: "/mall/phoneBill",
        name: "phoneBill",
        component: PhoneBill,
        meta: {
          showTabbar: false,
          showBack: true,
        },
      },
      {
        path: "/mall/CouponCenter",
        name: "CouponCenter",
        component: CouponCenter,
        meta: {
          showTabbar: false,
          showBack: true,
        },
      },
      {
        path: "/mall/couponSearchList",
        name: "couponSearchList",
        component: CouponSearch,
        meta: {
          showTabbar: false,
          showBack: false,
        },
      },
      {
        path: "/mall/searchCoupon",
        name: "searchCoupon",
        component: SearchCoupon,
        meta: {
          showTabbar: false,
          showBack: false,
        },
      },
    ],
  },

  {
    path: "*",
    redirect: "/mall/home",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
