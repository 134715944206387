const baseUrl = configs.baseUrl;
export default {
  GetMallCustomerService: baseUrl + "/api/TenantInfo/GetMallCustomerService", // 获取商城信息
  getLoginMsg: baseUrl + "/api/Login/SendPhoneCode", // 获取登录短信验证码
  login: baseUrl + "/api/Login/LoginByPhone", // 登录注册
  authToken: baseUrl + "/api/authorization_code", // 获取token
  refreshToken: baseUrl + "/api/Login/RefreshToken", // 刷新token
  getUserInfo: configs.host.auth + "/connect/userinfo", // 获取用户信息
  sendPnoneCode: baseUrl + "/api/UserInfo/SendPhoneCode", // 修改 绑定手机号 短信验证
  CheckPhoneCode: baseUrl + "/api/UserInfo/CheckPhoneCode", // 修改手机号检验
  UpdateMobile: baseUrl + "/api/UserInfo/UpdateMobile", // 修改手机号
  getBannerList: baseUrl + "/api/TenantBannerConfig/query", // 获取banner列表
  getCateList: baseUrl + "/api/ProductCate/page", // 商品分类
  getTagProductList: baseUrl + "/api/TagLibrary/tagtenantproductpage", // 标签商品列表 猜你喜欢
  ReceiveCoupon: baseUrl + "/api/UserInfo/ReceiveCoupon", // 领取卡券
  GetCouponInfoByCardNumber: baseUrl + "/api/UserInfo/GetPopularizeInfo", // 根据卡券编码获取卡券信息
  GetMyCouponQty: baseUrl + "/api/UserInfo/GetMyCouponQty", // 获取卡包数量
  getDigitaldetail: baseUrl + "/api/TenantProductGroup/GetDetail", // 获取数字商品详情
  getTemplate: baseUrl + "/api/product/upperDigitalProductTemplateNew", // 获取充值模板
  getOrderList: baseUrl + "/api/order/orderpage", // 获取订单列表
  GetAvailableCoupons: baseUrl + "/api/UserInfo/GetAvailableCoupons", // 获取下单时可用卡券列表
  unifysendorder: baseUrl + "/api/order/unifysendorder", // 商品下单
  confirmOrder: baseUrl + "/api/order/confirmOrder", // 确认订单
  getWxOpenId: baseUrl + "/api/order", // 获取微信公众号静默登录后的openId
  ordertopay: baseUrl + "/api/order/ordertopay", // 订单支付
  orderPayResultQuery: baseUrl + "/api/order/orderPayResultQuery", // 查询订单支付结果
  getOrderPayMoney: baseUrl + "/api/order/getOrderPayMoney", // 获取订单支付金额
  cancelorder: baseUrl + "/api/order/cancelorder", // 取消订单
  orderdetail: baseUrl + "/api/order/orderdetail", // 订单详情
  GetMyCoupons: baseUrl + "/api/UserInfo/GetMyCoupons", // 获取我的卡券列表
  ProductCate: baseUrl + "/api/TagLibrary/tenantproducttaglist", // 获取商品分类
  getProductInfo: baseUrl + "/api/product/get", // 获取商品详情
  digitalproductpage: baseUrl + "/api/TenantProductGroup/GetPageList", // 商品列表
  getOrderSecret: baseUrl + "/api/order/ordercard", // 卡密订单查询卡密列表
  getGoldOrderInfo: baseUrl + "/api/order/goldplan", // jsapi支付完成后获取订单信息
  getPayTypeList: baseUrl + "/api/TenantPaymementConfig/GetTenantPayment", // 获取支付方式
  getPhoneProduct: baseUrl + "/api/tenantproduct/GetTenantPhoneProductPage", // 获取话费列表,
  getChargeFacevalue: baseUrl + "/api/tenantproduct/GetChargeFaceValue", // 获取可充值话费列表
  GetOrderAfterSaleDetail: baseUrl + "/api/order/OrderAfterSaleDetail", // 获取订单售后详情
  GetRechargeAccountHistory:
    baseUrl + "/api/TenantProductGroup/GetRechargeAccountHistory", // 获取用户历史充值账号记录
  GetAvailableReceiveCouponsByGroupId:
    baseUrl + "/api/TenantProductGroup/GetAvailableReceiveCouponsByGroupId", // 根据商品包id获取可领取的优惠券列表（商城详情页面）
  getPosterUrl: baseUrl + "/api/TenantProductGroup/share", // 获取海报URL
  checkTenantStatus: baseUrl + "/api/TenantInfo/CheckTenantStatus", // 验证租户状态是否有效/未过期
  getHomeCouponInfo: baseUrl + "/api/coupon/center/get", // 获取首页优惠券信息
  getCouponListByTagId: baseUrl + "/api/coupon/center/tag/query", // 根据标签查询优惠券列表（限时闪购，爆款推荐）
  getCouponListBySearch: baseUrl + "/api/coupon/center/query", // 查询优惠券列表(用于搜索)
  getCouponCateList: baseUrl + "/api/coupon/center/cate/query", // 查询包含优惠券的商品类目
};
