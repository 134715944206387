import Vue from "vue";
import Vuex from "vuex";
import {
  GetMyCouponQty,
  getUserInfo,
  GetMallCustomerService,
} from "@/api/common";
import { ServiceModel } from "../model/serviceModel";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: true, // 设置页面刷新时loading状态
    webDisabled: true, // 网站是否被禁用
    noPage: false, // 网站是否被禁用
    showLogin: false,
    isLogin: Boolean(localStorage.getItem("access_token")),
    userInfo: JSON.parse(localStorage.getItem("userInfo") || "{}"),
    showTicketModal: false,
    cardCount: 0,
    telReg: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
    poductName: "",
    defaultProductImg: require("../assets/images/defaultProductImg.png"), // 商品默认图片
    isInWx:
      window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
      "micromessenger", // 是否在微信环境下
    isInAli:
      window.navigator.userAgent.toLowerCase().match(/AlipayClient/i) ==
      "alipayclient",
    isMiniProgram: false, // 是否在微信小程序环境中
    serviceData: {}, // 客服信息
    showServiceModal: false, // 是否展示客服信息
    mallInfo: { mallName: "数字权益商城", enableService: 0 }, // 商城信息
    serviceList: [], // 客服列表
  },
  mutations: {
    // 登录弹框
    setLoginBox(state, show = false) {
      state.showLogin = show;
    },
    // 设置登录状态
    setLoginStatus(state, { status = false }) {
      state.isLogin = status;
    },
    // 设置Loading状态
    setLoading(state, status = true) {
      state.loading = status;
    },
    setNoPage(state, status = false) {
      state.noPage = status;
    },
    // 设置领取卡券弹框状态
    setTicketShow(state, { show = false }) {
      state.showTicketModal = show;
    },
    // 设置卡包数量
    setCardCount(state, count) {
      state.cardCount = count;
    },
    // 设置用户信息
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    // 设置商品搜索的名称
    setProductName(state, poductName) {
      state.poductName = poductName;
    },
    // 设置网站是否被禁用
    setWebSiteDisabled(state, show) {
      state.webDisabled = show;
    },
    // 设置是否在微信小程序环境
    setIsMiniProgram(state, status) {
      state.isMiniProgram = status;
    },
    // 设置客服展示框
    setServiceModal(state, status) {
      state.showServiceModal = status;
    },
    // 设置客服信息
    setServiceData(state, data = {}) {
      state.serviceData = data;
    },
    // 设置商城信息
    setMallInfo(state, data = {}) {
      state.mallInfo = data;
    },
    // 设置客服列表
    setServiceList(state, list = []) {
      state.serviceList = list;
    },
  },
  actions: {
    // 获取我的卡包数量
    dispatchGetCardCount({ commit }) {
      GetMyCouponQty().then((res) => {
        if (res && res.code == 0) {
          commit("setCardCount", res.data);
        } else {
          // Toast(res.message);
        }
      });
    },
    // 获取用户信息
    async getUserInfo({ commit }) {
      try {
        const res = await getUserInfo();
        if (res) {
          localStorage.setItem("userInfo", JSON.stringify(res || {}));
          commit("setUserInfo", res || {});
        } else {
          // Toast("用户信息获取失败");
        }
      } catch (error) {
        // Toast("用户信息获取错误", error);
      }
    },
    // 获取商城信息（商城名称、客服信息）
    async getMallInfo({ commit }) {
      const res = await GetMallCustomerService();
      if (res && res.code == 0) {
        const data = res.data || {};
        commit("setMallInfo", data);
        // 设置keywords
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute(
            "content",
            `${data.mallName ||
              "数字权益商城"},权益兑换,数字商品,充值,营销,商城,购物`
          );
        // 设置客服列表
        const { customerServiceConfig = [] } = data;
        if (customerServiceConfig.length) {
          const list = customerServiceConfig.map((item) => {
            // serviceType:客服类型 10.电话客服 20.QQ客服 30.微信客服 40.其他客服  90.53客服
            const service = new ServiceModel(
              item.serviceType,
              item.serviceContent
            );
            return {
              name: item.serviceName,
              href: service.getHref(),
              src: service.getSrc(),
              click: item.serviceType == 90,
              type: item.serviceType,
              number: item.serviceContent,
              tip:
                item.serviceType == 20
                  ? "若要跳转，请先安装QQ客户端"
                  : "请拨打或添加客服联系方式",
            };
          });
          commit("setServiceList", list);
        }
      }
    },
    // 点击客服
    serviceAction({ commit, state }, data = {}) {
      const { type, name, number, tip } = data;
      if (type == 30 || type == 40 || type == 20) {
        // 微信客服 其他客服  设置客服信息  展示客服弹框
        commit("setServiceData", { title: name, number, type, tip });
        commit("setServiceModal", true);
      }
      if (type == 90) {
        // 53客服 跳转53客服
        if (state.isMiniProgram) {
          // 微信小程序
          Vue.prototype.$wxTo53Service();
        } else {
          const link53 = state.serviceList.find((item) => item.type == 90);
          if (link53) {
            window.open(link53.number);
          }
        }
      }
    },
  },
  getters: {
    // 打开QQ客服链接  解决IOS 浏览器中打开跳转到APP store问题
    qqLink: (state) => {
      const { isInWx, serviceList = [] } = state;
      const qqService = serviceList.find((item) => item.type == 20);
      let qqNumber = qqService ? qqService.number || "" : "";

      if (isInWx) {
        return `http://wpa.qq.com/msgrd?v=3&uin=${qqNumber}&site=qq&menu=yes`;
      }
      return `mqqwpa://im/chat?chat_type=wpa&uin=${qqNumber}&version=1&src_type=web&web_src=oicqzone.com`;
    },
  },
  modules: {},
});
