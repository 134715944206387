import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Toast } from "vant";
import "./assets/css/common.less";
import { Lazyload, Tab, Tabs } from "vant";
import _ from "lodash";
import wx from "weixin-js-sdk";

function setLoading(text, time) {
  Toast.loading({
    message: text || "加载中...",
    forbidClick: true,
    duration: time || 0,
  });
}

Vue.config.productionTip = false;
Vue.prototype.$toast = Toast;
Vue.prototype.$loading = setLoading;
Vue.prototype._ = _;

// 微信登录
Vue.prototype.$wxLogin = function(url) {
  const clientId = window.configs?.clientId;
  // 判断是在微信浏览器还是微信小程序  20：微信小程序 10：微信环境
  const channelType = store.state.isMiniProgram ? 20 : 10;
  const nowHref = url || encodeURIComponent(location.href);
  location.href = `${window.configs.host.auth}/account/LoginByThirdPlatform?channelType=${channelType}&clientId=${clientId}&tenantDomain=${nowHref}`;
};

// 微信小程序中跳转到53客服
Vue.prototype.$wxTo53Service = function() {
  wx.miniProgram.navigateTo({
    url: "/pages/service/index",
  });
};

//引入svg组件
import IconSvg from "./components/IconSvg";

// //全局注册svg-icon
Vue.component("svg-icon", IconSvg);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
