export class ServiceModel {
  constructor(serviceType, serviceContent) {
    // 获取 href
    this.getHref = function() {
      if (serviceType == 10) {
        return `tel:${serviceContent}`;
      }
      return "javascript:void(0);";
    };

    // 获取 src
    this.getSrc = function() {
      if (serviceType == 10) {
        return require("../assets/images/service-icon/phone.png");
      }
      if (serviceType == 20) {
        return require("../assets/images/service-icon/qq.png");
      }
      if (serviceType == 30) {
        return require("../assets/images/service-icon/wx.png");
      }
      return require("../assets/images/service-icon/other.png");
    };
  }
}
