import axios from "axios";
import { Toast } from "vant";
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { refreshToken } from "@/api/common";

axios.defaults.withCredentials = true; // 允许跨域且携带 Cookie（或自定义头）。默认值：false
axios.defaults.timeout = 500000; // 设置请求超时时间（ms）不超过半分钟
axios.defaults.headers.common.Authorization = ""; // 携带的自定义头
axios.defaults.headers.common.accept = ""; // 携带的自定义头
axios.defaults.headers["Content-Type"] = "application/json"; // 设置请求提内容类型，其他可选值：application/x-www-form-urlencoded

let isRefreshing = false; // 标记是否正在刷新 token
let requests = []; // 存储待重发请求的数组

axios.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem("access_token") || "";
    const refresh_token = localStorage.getItem("refresh_token");
    config.headers.Authorization = `Bearer ${access_token}`;

    // isAuth  接口是否需要权限（是否需要登录）
    if (config.isAuth && access_token && refresh_token) {
      const loginTime = localStorage.getItem("login_time") || 0; // 登录时存入的时间
      const gapTime = new Date().getTime() / 60 / 1000 - loginTime / 60 / 1000;
      // console.log("时间差：", gapTime, "登录时间：", loginTime);
      const expireTime = window.configs.expireTime || 25;
      // 判断时间差 如果大于设定时间 刷新token
      if (gapTime >= expireTime) {
        // 立即刷新token
        if (!isRefreshing) {
          console.log("刷新token ing");
          isRefreshing = true;
          refreshToken({
            refreshToken: refresh_token,
            scope: window.configs.authScope,
          })
            .then((res) => {
              const { access_token, refresh_token } = res.data;
              localStorage.setItem("login_time", new Date().getTime());
              localStorage.setItem("access_token", access_token);
              localStorage.setItem("refresh_token", refresh_token);
              config.headers.Authorization = `Bearer ${access_token}`;
              isRefreshing = false;
              return access_token;
            })
            .then((access_token) => {
              console.log("刷新access_token成功，执行队列");
              requests.forEach((cb) => cb(access_token));
              // 执行完成后，清空队列
              requests = [];
            })
            .catch((res) => {
              requests = [];
              accessFn();
              console.error("refresh access_token error: ", res);
            })
            .finally(() => {
              requests = [];
              isRefreshing = false;
            });

          const retryOriginalRequest = new Promise((resolve) => {
            requests.push((access_token) => {
              // 因为config中的access_token是旧的，所以刷新access_token后要将新access_token传进来
              config.headers.Authorization = `Bearer ${access_token}`;
              resolve(config);
            });
          });
          return retryOriginalRequest;
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function accessFn() {
  localStorage.clear();
  sessionStorage.clear();
  store.commit("setCardCount", 0);
  store.commit("setLoginStatus", { status: false });
  store.commit("setUserInfo", {});
  // 是否是微信
  const isInWx =
    window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
    "micromessenger";
  if (isInWx) {
    // 微信登录
    Vue.prototype.$wxLogin();
  } else {
    if (
      location.href.toLowerCase().includes("/mall/home") ||
      location.href.toLowerCase().includes("/mall/productdetails") ||
      location.href.toLowerCase().includes("/mall/account") ||
      location.href.toLowerCase().includes("/mall/couponcenter")
    ) {
      return;
    }
    store.commit("setLoginBox", true);
  }
}

axios.interceptors.response.use(
  function(response) {
    store.commit("setLoading", false);
    return response.data;
  },
  function(error) {
    Toast.clear();
    // 对响应错误做点什么
    if (error.response) {
      const errorMsg = error.response.data.message;
      switch (error.response.status) {
        case 400:
          Toast(errorMsg || `请求参数（data）格式错误！`);
          break;
        case 401:
          // Toast(`请登录！`);
          accessFn();
          break;
        case 403:
          // Toast(errorMsg || `权限不足，请重新登录！`);
          accessFn();

          break;
        case 404:
          Toast(errorMsg || `请求 URL 格式错误！`);
          break;
        case 405:
          Toast(errorMsg || `请求 Method 格式错误！`);
          break;
        case 406:
          Toast(errorMsg || `请求 Content-Type 格式错误！`);
          break;
        case 408:
          Toast(errorMsg || `请求超时！`);
          break;
        case 600:
          store.commit("setNoPage", true);
          location.replace("/404");
        default:
          break;
      }
      if (error.response.status != 600) {
        store.commit("setLoading", false);
      }
    } else if (error.request) {
      store.commit("setLoading", false);
      // 请求被提出，但是没有收到任何回应
    } else {
      store.commit("setLoading", false);
      if (
        error.message ===
        `timeout of ${error.config && error.config.timeout}ms exceeded`
      ) {
        return Toast("请求超时，请刷新页面重新请求！");
      }
      // 2 ）网络错误
      if (error.message === "Network Error") {
        return Toast("网络错误！");
      }
    }
    return Promise.reject(error);
  }
);

axios.get = async (url, params, isAuth) => {
  // isAuth: 接口是否需要权限
  try {
    let res = await axios(url, {
      ...params,
      isAuth: isAuth || false,
    });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

axios.post = async (url, data, isAuth) => {
  try {
    let res = await axios({
      url,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/json",
      },
      isAuth: isAuth || false,
    });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default axios;
