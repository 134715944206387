<template>
  <van-popup v-model="show" round>
    <div class="wechat-info-box">
      <h3 class="title">
        {{ serviceData.title }}
      </h3>
      <span class="number">{{ serviceData.number }}</span>
      <span class="tip">{{ serviceData.tip }}</span>
      <div class="action-box">
        <span class="cancel" @click="cancel">{{
          serviceData.type == 20 ? "跳转" : "返回"
        }}</span>
        <span
          class="copy"
          :data-clipboard-text="serviceData.number"
          @click="copy('copy')"
          >复制号码</span
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Popup } from "vant";
import Clipboard from "clipboard";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "WeChatModal",
  components: {
    [Popup.name]: Popup,
  },

  computed: {
    ...mapState(["showServiceModal", "serviceData"]),
    ...mapGetters(["qqLink"]),
    show: {
      get() {
        return this.showServiceModal;
      },
      set(val) {
        this.setServiceModal(val);
      },
    },
  },
  methods: {
    ...mapMutations(["setServiceModal"]),
    cancel() {
      this.setServiceModal(false);
      if (this.serviceData.type == 20) {
        const a = document.createElement("a");
        a.setAttribute("href", this.qqLink);
        a.setAttribute("target", "_blank");
        a.setAttribute("id", "startTelMedicine");
        a.setAttribute("style", "display:none");
        // 防止反复添加
        if (document.getElementById("startTelMedicine")) {
          document.body.removeChild(
            document.getElementById("startTelMedicine")
          );
        }
        document.body.appendChild(a);
        a.click();
      }
    },
    copy(name) {
      console.log(name); // name为class类名
      const clipboard = new Clipboard(`.${name}`);
      clipboard.on("success", (e) => {
        this.$toast.success("复制成功！");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast("该浏览器不支持自动复制！");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less">
.wechat-info-box {
  width: 5.9rem;
  padding: 0.4rem;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    height: 0.4rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .number {
    width: 100%;
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
    margin-top: 0.32rem;
  }
  .tip {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 0.16rem;
  }
  .action-box {
    width: 100%;
    margin-top: 0.48rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 2.4rem;
      height: 0.72rem;
      border-radius: 0.52rem;
      border: 0.02rem solid #cdcdcd;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .copy {
      background: #ff5b42;
      border-color: #ff5b42;
      color: #ffffff;
    }
  }

  .service-tip {
    font-size: 0.24rem;
    color: #ccc;
    width: 100%;
    padding-left: 0.14rem;
  }
}
</style>