import axios from "@/utils/axios";
import Api from "@/configs/api";

// 判断商户链接是否失效
export function checkTenantStatus(data) {
  return axios.get(Api.checkTenantStatus, { params: data });
}

// 获取登录短信验证码
export function getLoginMsg(data) {
  return axios.post(Api.getLoginMsg, data);
}

// 登录
export function login(data) {
  return axios.post(Api.login, data);
}

// 获取token
export function authToken(data) {
  return axios.post(Api.authToken, data);
}

// 刷新token
export function refreshToken(data) {
  return axios.post(Api.refreshToken, data);
}

// 获取用户信息
export function getUserInfo(data) {
  return axios.get(Api.getUserInfo, { params: data }, true);
}

// 修改 绑定手机号 短信验证
export function sendPnoneCode(data) {
  return axios.post(Api.sendPnoneCode, data, true);
}

// 修改手机号检验
export function CheckPhoneCode(data) {
  return axios.post(Api.CheckPhoneCode, data, true);
}

// 修改手机号
export function UpdateMobile(data) {
  return axios.post(Api.UpdateMobile, data, true);
}

// 获取banner list
export function getBannerList(data) {
  return axios.get(Api.getBannerList, { params: data });
}

// 获取分类菜单
export function getCateList(data) {
  return axios.get(Api.getCateList, { params: data });
}

// 获取标签商品列表  猜你喜欢
export function getTagProductList(data) {
  return axios.get(Api.getTagProductList, { params: data });
}

// 领取卡券
export function ReceiveCoupon(data) {
  return axios.post(Api.ReceiveCoupon, data, true);
}

// 根据卡券编码获取卡券信息
export function GetCouponInfoByCardNumber(data) {
  return axios.get(Api.GetCouponInfoByCardNumber, { params: data });
}

// 获取卡包数量
export function GetMyCouponQty(data) {
  return axios.get(Api.GetMyCouponQty, { params: data }, true);
}

// 获取数字商品详情
export function getDigitaldetail(data) {
  return axios.get(Api.getDigitaldetail, { params: data });
}

// 获取商品详情
export function getProductInfo(data) {
  return axios.get(Api.getProductInfo, { params: data });
}

// 获取充值模板
export function getTemplate(data) {
  return axios.get(Api.getTemplate, { params: data });
}

// 获取订单列表
export function getOrderList(data) {
  return axios.get(Api.getOrderList, { params: data }, true);
}

// 获取下单时可用卡券列表
export function GetAvailableCoupons(data) {
  return axios.get(Api.GetAvailableCoupons, { params: data }, true);
}

// 获取我的卡包列表
export function GetMyCoupons(data) {
  return axios.get(Api.GetMyCoupons, { params: data }, true);
}

// 获取商品分类
export function ProductCate() {
  return axios.get(Api.ProductCate);
}

// 商品下单
export function unifysendorder(data) {
  return axios.post(Api.unifysendorder, data, true);
}

// 确认订单
export function confirmOrder(data) {
  return axios.post(Api.confirmOrder, data, true);
}

// 订单支付
export function ordertopay(data) {
  return axios.post(Api.ordertopay, data, true);
}

// 获取订单支付金额
export function getOrderPayMoney(data) {
  return axios.get(Api.getOrderPayMoney, { params: data }, true);
}

// 查询订单支付结果
export function orderPayResultQuery(data) {
  return axios.get(Api.orderPayResultQuery, { params: data }, true);
}

// 取消订单
export function cancelorder(data) {
  return axios.post(Api.cancelorder, data, true);
}

// 订单详情
export function orderdetail(data) {
  return axios.get(Api.orderdetail, { params: data }, true);
}

// 获取微信openid
export function getWxOpenId(data) {
  return axios.get(Api.getWxOpenId, { params: data }, true);
}

// 商品列表
export function digitalproductpage(data) {
  return axios.get(Api.digitalproductpage, { params: data });
}

// 卡密订单查询卡密列表
export function getOrderSecret(data) {
  return axios.get(Api.getOrderSecret, { params: data }, true);
}

// jsapi支付完成后获取订单信息
export function getGoldOrderInfo(data) {
  return axios.post(Api.getGoldOrderInfo, data, true);
}

// 支付方式
export function getPayTypeList(data) {
  return axios.get(Api.getPayTypeList, { params: data }, true);
}

// 获取话费列表
export function getPhoneProduct(data) {
  return axios.get(Api.getPhoneProduct, { params: data });
}

// 获取可充值话费列表
export function getChargeFacevalue(data) {
  return axios.get(Api.getChargeFacevalue, { params: data }, true);
}

// 获取订单售后详情
export function GetOrderAfterSaleDetail(data) {
  return axios.get(Api.GetOrderAfterSaleDetail, { params: data }, true);
}

// 获取用户历史充值账号记录
export function GetRechargeAccountHistory(data) {
  return axios.get(Api.GetRechargeAccountHistory, { params: data }, true);
}

// 根据商品包id获取可领取的优惠券列表（商城详情页面）
export function GetAvailableReceiveCouponsByGroupId(data) {
  return axios.get(Api.GetAvailableReceiveCouponsByGroupId, { params: data });
}

// 获取海报URL
export function getPosterUrl(data) {
  return axios.post(Api.getPosterUrl, data);
}

// 获取首页优惠券信息
export function getHomeCouponInfo(data) {
  return axios.get(Api.getHomeCouponInfo, { params: data }, true);
}

// 根据标签查询优惠券列表（限时闪购，爆款推荐）
export function getCouponListByTagId(data) {
  return axios.get(Api.getCouponListByTagId, { params: data }, true);
}
// 查询优惠券列表(用于搜索)
export function getCouponListBySearch(data) {
  return axios.get(Api.getCouponListBySearch, { params: data }, true);
}
// 查询包含优惠券的商品类目
export function getCouponCateList(data) {
  return axios.get(Api.getCouponCateList, { params: data }, true);
}

// 获取商城自定义信息
export function GetMallCustomerService(data) {
  return axios.get(Api.GetMallCustomerService, { params: data });
}
